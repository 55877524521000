import React, { memo } from 'react';
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import acceptSound from "./accept.mp3";
import findGif from "../css/loading_old.gif";
import playSound from "./play.mp3";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

const BetCard = memo(({ allgame, user, deleteChallenge, getPost, RejectGame, winnAmount, AcceptChallang, updateChallenge }) => {

  return (
    <div className={`${css.betCard} mt-2`}>
      {/* <span className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase ${css.betTitleDiv}`}>
        CHALLENGE FROM
        <span className="ml-1" style={{ color: "brown" }}>
          {allgame.Created_by.Name}
        </span>

        {user === allgame.Created_by._id && allgame.Status === "new" && (
          <button
            className="p-1 m-1 mb-1 ml-auto btn-danger btn-sm"
            onClick={() => deleteChallenge(allgame._id)}
          >
            DELETE
          </button>
        )}

        {user === allgame.Created_by._id && allgame.Status === "requested" && (
          <div className="d-flex ml-auto align-items-center">
            <Link
              to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
              onClick={() => getPost(allgame._id)}
              style={{ textDecoration: 'none' }}
            >
              <button className="btn btn-sm" style={{ backgroundColor: '#007bff', color: '#000' }}>
                START
              </button>
            </Link>
            <button
              className="btn btn-sm ml-1"
              onClick={() => RejectGame(allgame._id)}
              style={{ backgroundColor: 'red', color: '#000' }}
            >
              REJECT
            </button>
          </div>
        )}
      </span> */}

      {/* <div className={`d-flex pl-3 ${css.betBodyDiv}`}>
        <div className="pr-3 pb-1">
          <span className={css.betCardSubTitle}>Entry Fee</span>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/Images/LandingPage_img/global-rupeeIcon.png"}
              alt=""
              width="21px"
            />
            <span className={css.betCardAmount}>
              {allgame.Game_Ammount}
            </span>
          </div>
        </div>

        <div>
          <span className={css.betCardSubTitle}>Prize</span>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/Images/LandingPage_img/global-rupeeIcon.png"}
              alt=""
              width="21px"
            />
            <span className={css.betCardAmount}>
              {allgame.Game_Ammount + winnAmount(allgame.Game_Ammount)}
            </span>
          </div>
        </div>

        {user !== allgame.Created_by._id && allgame.Status === "new" && (
          <button
            className={`btn btn-sm ${css.playButton}`}
            style={{ backgroundColor: '#007bff', color: '#000' }}
            onClick={() => AcceptChallang(allgame._id)}
          >
            Play
          </button>
        )}

        {user === allgame.Created_by._id && allgame.Status === "new" && (
          <div className="text-center col-5 ml-auto mt-auto mb-auto">
            <div className="pl-2 text-center">
              <img src={findGif} style={{ width: "15px", height: "15px" }} alt="loading" />
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>Finding Player!</span>
            </div>
          </div>
        )}

        {user !== allgame.Created_by._id && allgame.Status === "requested" && (
          <div className="d-flex ml-auto align-items-center">
            <div className="btn btn-sm" style={{ backgroundColor: '#007bff', color: '#000' }}>
              Requested
            </div>
            <button
              className="btn btn-sm ml-1"
              onClick={() => RejectGame(allgame._id)}
              style={{ backgroundColor: 'red', color: '#000' }}
            >
              Cancel
            </button>
          </div>
        )}

        {user !== allgame.Created_by._id && allgame.Status === "running" && (
          <div className="d-flex ml-auto align-items-center">
            <audio src={playSound} autoPlay />
            <Link
              className="btn btn-sm"
              to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
              onClick={() => updateChallenge(allgame._id)}
              style={{ backgroundColor: '#007bff', color: '#000' }}
            >
              Start
            </Link>
          </div>
        )}

        {user === allgame.Created_by._id && allgame.Status === "requested" && (
          <div className="d-flex ml-auto align-items-center mr-5 mt-1">
            <audio src={acceptSound} autoPlay />
            <div className="text-center col">
              <div className="pl-2">
                <img
                  src={allgame.Accepetd_By.avatar || `/Images/author.svg`}
                  alt=""
                  width="40px"
                  height="40px"
                  className="rounded-circle mt-1"
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className={css.betCard_playerName}>{allgame.Accepetd_By.Name}</span>
              </div>
            </div>
          </div>
        )}
      </div> */}
      <div className="bet-card-container my-2 card" id="chdiv-327412">
        <div className="d-flex align-items-center justify-content-between card-header">
          <span>Challenge set by {allgame.Created_by.Name}</span>
          <span className="text-success fw-bold">Rs {allgame.Game_Ammount}</span>
        </div>
        <div className="d-flex align-items-center justify-content-between card-body">
          <div className="d-flex align-items-center flex-grow-1">
            {user === allgame.Created_by._id && allgame.Status === "new" && (
            <div className="d-flex align-items-center justify-content-start">
              <div role="status" className="me-2 spinner-border">
                <span className="visually-hidden"></span>
              </div>
              <span className="text-capitalize finding-player-text">finding player</span>
            </div>)}
            {user === allgame.Created_by._id && allgame.Status === "requested" && (
              <div className="d-flex align-items-center justify-content-start">
                <audio src={acceptSound} autoPlay />
                <div className="text-center col">
                  <div className="pl-2">
                    <img
                      src={allgame.Accepetd_By?.avatar || `/Images/author.svg`}
                      alt=""
                      width="40px"
                      height="40px"
                      className="rounded-circle mt-1"
                    />
                  </div>
                  <div style={{ lineHeight: 1 }}>
                    <span className={css.betCard_playerName}>{allgame?.Accepetd_By?.Name}</span>
                  </div>
                </div>
              </div>
             )}
          </div>
          <div className="d-flex align-items-center">
            <div className="hstack gap-2">
              {user === allgame.Created_by._id && allgame.Status === "new" &&
                (<button className="btn btn-danger btn-sm" onClick={() => deleteChallenge(allgame._id)}>Delete</button>)}
              {user === allgame.Created_by._id && allgame.Status === "requested" &&
                (
                  <>
                    <Link
                      to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
                      onClick={() => getPost(allgame._id)}
                      style={{ textDecoration: 'none' }}
                    >
                      <button className="btn btn-danger btn-sm btn-start" onClick={() => deleteChallenge(allgame._id)}>Start</button>
                    </Link>
                    <button className="btn btn-danger btn-sm" onClick={() => RejectGame(allgame._id)}>Reject</button>
                  </>
                )
              }
              {user !== allgame.Created_by._id && allgame.Status === "new" && (
                <button
                  className={`btn btn-sm`}
                  style={{ backgroundColor: '#007bff' }}
                  onClick={() => AcceptChallang(allgame._id)}
                >
                  Play
                </button>
              )}
              {user !== allgame.Created_by._id && allgame.Status === "requested" && (
                <div className="d-flex ml-auto align-items-center">
                  <div className="btn btn-sm" style={{ backgroundColor: '#007bff' }}>
                    Requested
                  </div>
                  <button
                    className="btn btn-sm ml-1"
                    onClick={() => RejectGame(allgame._id)}
                    style={{ backgroundColor: 'red' }}
                  >
                    Cancel
                  </button>
                </div>
              )}

              {user !== allgame.Created_by._id && allgame.Status === "running" && (
                <div className="d-flex ml-auto align-items-center">
                  <audio src={playSound} autoPlay />
                  <Link
                    className="btn btn-sm"
                    to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
                    onClick={() => updateChallenge(allgame._id)}
                    style={{ backgroundColor: '#007bff', color: '#000' }}
                  >
                    Start
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BetCard;
