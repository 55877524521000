import React, { useEffect, useState } from 'react';
import Rightcontainer from '../Components/Rightcontainer';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

const Mywallet = () => {
    const history = useHistory();
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
    
    const [user, setUser] = useState();
    const [clientWidth, setClientWidth] = useState(window.innerWidth);
    const access_token = localStorage.getItem("token");

    useEffect(() => {
        if (!access_token) {
            history.push("/login");
        }
        const headers = {
            Authorization: `Bearer ${access_token}`
        };
        axios.get(baseUrl + 'me', { headers })
            .then((res) => {
                setUser(res.data);
            }).catch((e) => {
                console.log(e);
                if (e.response.status === 401) {
                    localStorage.removeItem('token');
                    history.push("/login");
                }
            });
    }, [history, baseUrl, access_token]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
              width: clientWidth > 500 ? "30%" : "100%",
              alignItems: 'center',
          marginTop:"50px",
            backgroundColor: '#f0f2f5',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '500px',
            }}>
                {/* Order History Link */}
                <div style={{
                    width: '100%',
                    marginBottom: '20px',
                    marginTop: '20px',
                    padding: '16px',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    textAlign: 'center'
                }}>
                    <Link to="/transaction-history" style={{ textDecoration: 'none', color: '#6c757d' }}>
                        <img width="20px" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} alt="" style={{ marginBottom: '10px' }} />
                        <div style={{ fontSize: '1em' }}>Order History</div>
                    </Link>
                </div>

                {/* Deposit Chips Card */}
                <div style={{
                    width: '100%',
                    marginBottom: '20px',
                    padding: '16px',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    textAlign: 'center'
                }}>
                    <h5 style={{ marginBottom: '10px' }}>Deposit Chips</h5>
                    <p style={{ backgroundColor: '#cce5ff', color: '#000', padding: '10px', borderRadius: '5px' }}>
                        यह चिप्स Spin & Buy की गई चिप्स है इनसे सिर्फ गेम खेले जा सकते है !! Bank या UPI में निकाला नहीं जा सकता है.
                    </p>
                    <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '10px' }}>
                        Chips: {user && user.Wallet_balance}
                    </div>
                    <Link to="/addcase">
                        <button style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold'
                        }}>
                            Add
                        </button>
                    </Link>
                </div>

                {/* Winning Chips Card */}
                <div style={{
                    width: '100%',
                    padding: '16px',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    textAlign: 'center'
                }}>
                    <h5 style={{ marginBottom: '10px' }}>Winning Chips</h5>
                    <p style={{ backgroundColor: '#cce5ff', color: '#000', padding: '10px', borderRadius: '5px' }}>
                        यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हे Bank या UPI में निकाला जा सकता है !! इन चिप्स से गेम भी खेला जा सकता है.
                    </p>
                    <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '10px' }}>
                        Chips: {user && user.withdrawAmount}
                    </div>
                    <Link to="/Withdrawopt">
                        <button style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold'
                        }}>
                            Withdraw
                        </button>
                    </Link>
                </div>
            </div>
                <Rightcontainer />
            </div>
    );
};

export default Mywallet;
