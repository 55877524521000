import React from "react";
import { Link } from "react-router-dom";
import avtar1 from "../Avtar/Avatar1.png";
import avtar2 from "../Avtar/Avatar2.png";
import avtar3 from "../Avtar/Avatar3.png";
import avtar4 from "../Avtar/Avatar4.png";

export default function RunningCard({ runnig, user, winnAmount, game_type }) {
  const avatars = [avtar1, avtar2, avtar3, avtar4];
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  function getRandomAvatar() {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
  }

  const randomAvatar = getRandomAvatar();

  return (
    <div style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "10px", width: "auto",marginTop:"5px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={runnig.Created_by.avatar || `/Images/author.svg`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = randomAvatar;
            }}
            alt=""
            width="35px"
            height="35px"
            style={{ borderRadius: "50%" }}
          />
          <span style={{ marginLeft: "8px", fontWeight: "bold", fontSize: "14px" }}>
            {runnig.Created_by.Name || "Player 1"}
          </span>
        </div>

        <div style={{ fontSize: "24px", color: "#ff6600" }}><img src={"/Images/vs.webp"} style={{width:"20px"}}/></div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "8px", fontWeight: "bold", fontSize: "14px" }}>
            {runnig.Accepetd_By.Name || "Player 2"}
          </span>
          <img
            src={runnig.Accepetd_By.avatar || `/Images/author.svg`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getRandomAvatar();
            }}
            alt=""
            width="35px"
            height="35px"
            style={{ borderRadius: "50%" }}
          />
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "10px", color: "#28a745", fontWeight: "bold", fontSize: "16px" }}>
        Rs {runnig.Game_Ammount || 50}
      </div>

      {(user === runnig.Accepetd_By._id || user === runnig.Created_by._id) && (
<div style={{ textAlign: "center", marginTop: "10px" }}>
  <Link
    to={{
      pathname: `/viewgame1/${runnig._id}`,
      state: { prevPath: window.location.pathname },
    }}
    style={{
      display: "inline-block",
      backgroundColor: runnig.Status === "conflict" ? "red" : "#007bff",
      color: "#000",
      padding: "5px 15px",
      borderRadius: "4px",
      textDecoration: "none",
    }}
  ><span style={{color:"#fff"}}>
    View</span>
  </Link>
</div>
      )}
    </div>
  );
}
