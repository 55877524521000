import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Components/Header";
// import 'remixicon/fonts/remixicon.css'

const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        // console.log(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
        console.log(res.data);
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    Cashheader();
    fetchData();
    //eslint-disable-next-line
  }, []);

  const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <>
      <Header user={user} />
      <div>
        {/* <div className="leftContainer" style={{ height: "100vh" }}>
          <div className={`${css.center_xy} pt-5`}>
            <picture className="mt-1">
              <img
                alt="img"
                width="226px"
                src={process.env.PUBLIC_URL + "Images/refer/refer.png"}
                className="snip-img"
              />
            </picture>
            <div className="mb-1">
              <div className="font-15">
                Earn now unlimited
                <span aria-label="party-face">🥳</span>
              </div>
              <div className="d-flex justify-content-center">
                Refer your friends now!
              </div>
              <div className="mt-3 text-center font-9">
                Current Earning:
                <b>{user.referral_wallet}</b>
                <Link className="ml-2" to="/Redeem">
                  Redeem
                </Link>
              </div>
              <div className="text-center font-9">
                Total Earned:
                <b>{user.referral_earning}</b>
              </div>
              <div className={`${css.progress}`}>
                <div
                  className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                  aria-valuenow={user.referral_earning}
                  aria-valuemax={10000}
                  style={{ width: `${(user.referral_earning * 100) / 10000}%` }}
                ></div>
              </div>
              <div className="font-9">
                <span>Max: ₹10,000</span>
                <Link className="float-right" to="/update-pan">
                  Upgrade Limit
                </Link>
              </div>
              <div className={`${css.text_bold} mt-3 text-center`}>
                Your Refer Code: {user.referral_code}
                <i
                  className="ri-clipboard-fill ml-2 "
                  style={{ fontSize: "20px", color: "#007bff" }}
                  onClick={(e) => copyCode(e)}
                ></i>
              </div>

              <div className="d-flex justify-content-center">
                Total Refers:&nbsp;
                <b>{cardData && cardData}</b>
              </div>
            </div>
          </div>

          <div className="mx-3 my-3">
            <div className={`${css.font_11} ${css.text_bold}`}>
              Refer &amp; Earn Rules
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/giftbanner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: "63%" }}>
                <div>
                  When your friend signs up on Our website or App from your
                  referral link,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>1% Commission</strong>
                  on your
                  <strong>referral's winnings.</strong>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/banner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: "63%" }}>
                <div>Suppose your referral plays a battle for ₹10000 Cash,</div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>₹100 Cash</strong>
                  <strong></strong>
                </div>
              </div>
            </div>
          </div>

          <div className={`${css.refer_footer} pt-2 `}>
            <a
              href={`whatsapp://send?text=${encodeURIComponent(
                `Play Ludo and earn Rs10000 daily.
Commission Charge - 5% Only
Referral - 2% On All Games
24x7 Live Chat Support
Instant Withdrawal Via UPI/Bank
Register Now, My refer code is ${user.referral_code}.
👇
https://realludoplayer.com/login?referral=${user.referral_code}`
              )}`}
              style={{ width: "100%" }}
            >
              <button className="bg-green refer-button cxy w-100">
                Share on Whatsapp
              </button>
            </a>
          </div>
        </div> */}
        <div className="leftContainer" style={{ height: "100vh" }}>
          <div className="mb-3 refer-earning-container card">
            <div className="bg-light text-dark card-header">
              <center><b>Your Referral Earnings</b></center>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
                  <span className="text-capitalize fw-bold" style={{ fontSize: '0.8rem' }}><b>referred players</b></span>
                  <span>{user.referral_earning}</span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                  <span className="text-capitalize fw-bold" style={{ fontSize: '0.8rem' }}><b>Referral Earning</b></span>
                  <span>₹{user.referral_earning}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 refer-code-container card">
            <div className="bg-light text-dark card-header">
              <center><b>Referral Code</b></center>
            </div>
            <div className="card-body">
              <div>
                <div>
                  {/* <!-----<center><img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/refer.webp" alt="refer" width="150" height="150"></center>----> */}
                </div>
                <div>
                  <div>
                    <div>
                      <div className="input-group copy-button-group">
                        <input type="text" className="form-control p-2" disabled={true} value={user.referral_code} id="myInput2" />
                        <button className="btn btn-primary text-uppercase"  onClick={(e) => copyCode(e)} fdprocessedid="h1qk6f">copy</button>
                      </div>
                    </div>
                  </div>
                  <p className="text-uppercase fw-bold fs-3 p-0 m-0 my-3"></p>
                  <center><h5>OR</h5></center>
                  <p></p>
                  <div className="d-grid">
                    <a href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.%0ACommission Charge - 5% Only%0AReferral - 2% On All Games%0A24x7 Live Chat Support%0AInstant Withdrawal Via UPI/Bank%0ARegister Now, My refer code is ${user.referral_code}.%0A👇%0Ahttps://realludoplayer.com/register?referral=`}>
                      <button className="btn btn-success btn-md w-100" fdprocessedid="87ldb">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
                          <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z">
                          </path>
                        </svg>
                        <span className="ms-2 text-capitalize">share on whatsapp</span>
                      </button>
                    </a>
                  </div>
                  <div className="d-grid mt-2">
                    {/* <input hidden="" type="text" value="37802b61" id="myInput"> */}
                    <button className="btn btn-secondary btn-md w-100" fdprocessedid="rrwl8d">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z">
                        </path>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z">
                        </path>
                      </svg>
                      <span className="ms-2 text-capitalize"  onClick={(e) => copyCode(e)}>copy to clipboard</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider-x"></div>
          <div className="mb-3 how_works_container card">
            <div className="bg-light text-dark card-header">
              <center><b>How It Works</b></center>
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-group-item">You can refer and <b>Earn 2%</b> of your referral winning, every time</li>
                <li className="list-group-item">Like if your player plays for <b>10000</b> and wins, You will get <b>₹200</b> as referral amount.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refer;